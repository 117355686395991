import HomeIcon from '@mui/icons-material/HomeOutlined';
import HistoryIcon from '@mui/icons-material/HistoryOutlined';
import LeaderboardIcon from '@mui/icons-material/EmojiEventsOutlined';
import TeamIcon from '@mui/icons-material/GroupOutlined';
import TournamentsIocn from '@mui/icons-material/HistoryOutlined';
import DocumentsIcon from '@mui/icons-material/InsertDriveFileOutlined';
import VideocamOutlined from '@mui/icons-material/VideocamOutlined';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PlayerIcon from '@mui/icons-material/Person';

const menu = [
    {
        icon: HomeIcon,
        name: 'Главная',
        route: '/',
        mask: '^/$',
        permission: '17f7226e-0d33-4e82-bfc2-5b0d2fa145c2',
    },
    {
        icon: PlayerIcon,
        name: 'Работа над игрой',
        route: '/player',
        mask: '^/player$',
        permission: 'f8d01948-e264-465e-9283-239b2062a425',
    },
    {
        icon: HistoryIcon,
        name: 'История',
        route: '/history',
        mask: '^/history$',
        permission: '1b354bce-a9ed-4ee7-b63e-11fb93390e32',
    },
    // {
    //     icon: LeaderboardIcon,
    //     name: 'Лидерборд',
    //     route: '/leaderboard',
    //     mask: '^/leaderboard$',
    //     permission: 'd2b5c61f-02b5-4212-b018-25ea664f6898'
    // },
    // {
    //     icon: TeamIcon,
    //     name: 'Наша команда',
    //     route: '/team',
    //     mask: '^/team$',
    //     permission: '136a566e-3eb3-41ad-ad32-777dd8a7e592'
    // },
    {
        icon: TournamentsIocn,
        name: 'Расписатор',
        route: '/tournaments',
        mask: '^/tournaments$',
        permission: '8e7d0e5f-0c05-4c26-9053-311309158c73',
    },
    {
        icon: VideocamOutlined,
        name: 'Видео',
        route: '/video',
        mask: '/video/?[0-9]*',
        permission: '673a6475-133c-47cb-a670-a4a5f8500722',
    },
    {
        icon: DocumentsIcon,
        name: 'Документы',
        route: '/documents',
        mask: '^/documents$',
        permission: '8dda79e2-57e4-4225-bc64-948839abac9d',
    },
    {
        icon: AccountBalanceIcon,
        name: 'Студенты',
        route: '/students',
        mask: '^/students$',
        permission: 'c3b8eb57-7e64-4881-a25a-6cb4c5665aaf',
    },
];

export default menu;
